import React, {useEffect, useState} from 'react';
import * as styles from './price.module.scss';
import {Table} from "react-bootstrap";
import classNames from "classnames";


function Price() {


    return (
        <div className={styles.price}>
            <div className={styles.price__container}>
                <div className={classNames([`${styles.price__row}`], [`${styles.price__rowTitle}`])}>
                    <div className={styles.price__title}>
                        <h1>Цены на стоматологические услуги</h1>
                    </div>
                </div>

                <div className={styles.price__row}>
                    <div className={styles.price__content}>

                        <Table striped responsive className={styles.price__table} borderless={true}>
                            <thead>
                            <tr className="price__title">
                                <th colSpan={4}>Общие виды работ</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Консультация по всем вопросам лечения, протезирования</td>
                                <td>бесплатно</td>
                            </tr>
                            <tr>
                                <td>Диспансерный осмотр после лечения</td>
                                <td>бесплатно</td>
                            </tr>
                            <tr>
                                <td>Оформление документации(справки, выписки)</td>
                                <td>300 &#8381;</td>
                            </tr>
                            <tr>
                                <td>Анестезия</td>
                                <td>300 &#8381;</td>
                            </tr>
                            </tbody>
                            <thead>
                            <tr className="price__title">
                                <th colSpan={4}>Ортопедия</th>
                            </tr>
                            </thead>
                            <thead>
                            <tr className="price__title">
                                <th colSpan={4}>Несъёмное зубопротезирование</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Одиночная коронка, зуб (1 единица) металлокерамическая</td>
                                <td>5500 &#8381;</td>
                            </tr>
                            <tr>
                                <td>Фиксация коронки</td>
                                <td>500 &#8381;</td>
                            </tr>
                            <tr>
                                <td>Одиночная коронка, зуб (1 единица) металлическая литая</td>
                                <td>3000 &#8381;</td>
                            </tr>
                            </tbody>
                            <thead>
                            <tr className="price__title">
                                <th colSpan={4}>Съёмное зубопротезирование</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Съёмный протез с 1-2 зубами</td>
                                <td>3200-3800  &#8381;</td>
                            </tr>
                            <tr>
                                <td>Съёмный протез с 3-6 зубами</td>
                                <td>3800-4800  &#8381;</td>
                            </tr>
                            <tr>
                                <td>Съёмный протез с 7-10 зубами</td>
                                <td>5300-6300  &#8381;</td>
                            </tr>
                            <tr>
                                <td>полный съёмный протез</td>
                                <td>6000-7000  &#8381;</td>
                            </tr>
                            <tr>
                                <td>Бюгельный протез</td>
                                <td>от 30000 &#8381;</td>
                            </tr>
                            </tbody>
                            <thead>
                            <tr className="price__title">
                                <th colSpan={4}>Эндодонтия</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Эндодонтическое лечение и пломбирование 1-канального зуба (с анестезией)</td>
                                <td>1800 &#8381;</td>
                            </tr>
                            <tr>
                                <td>Эндодонтическое лечение и пломбирование 2-канального зуба (с анестезией)</td>
                                <td>2000 &#8381;</td>
                            </tr>
                            <tr>
                                <td>Эндодонтическое лечение и пломбирование 3-канального зуба (с анестезией)</td>
                                <td>2300 &#8381;</td>
                            </tr>
                            <tr>
                                <td>Пломбирование дополнительного канала</td>
                                <td>300 &#8381;</td>
                            </tr>
                            <tr>
                                <td>Распломбирование  1 канала, запломбированного цементом</td>
                                <td>800 &#8381;</td>
                            </tr>
                            <tr>
                                <td>Распломбирование  1 канала, запломбированного пастой, гуттаперчей</td>
                                <td>500 &#8381;</td>
                            </tr>
                            <tr>
                                <td>Удаление металлических штифтов ультразвуком</td>
                                <td>800 &#8381;</td>
                            </tr>
                            <tr>
                                <td>Удаление штифтовкладки ультразвуком</td>
                                <td>1500-2000 &#8381;</td>
                            </tr>
                            <tr>
                                <td>Медикаментозное лечение канала с использованием противовоспалительных, кальцийсодержащих паст (однократно)</td>
                                <td>400 &#8381;</td>
                            </tr>
                            <tr>
                                <td>Наложение девитализирующей пасты</td>
                                <td>200 &#8381;</td>
                            </tr>
                            </tbody>

                            <thead>
                            <tr className="price__title">
                                <th colSpan={4}>Терапия</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Анестезия</td>
                                <td>300  &#8381;</td>
                            </tr>
                            <tr>
                                <td>Лечение среднего кариеса и некариозных поражений</td>
                                <td>200  &#8381;</td>
                            </tr>
                            <tr>
                                <td>Лечение глубокого кариеса</td>
                                <td>400  &#8381;</td>
                            </tr>
                            <tr>
                                <td>Наложение пломбы из СИЦ химического отверждения</td>
                                <td>500  &#8381;</td>
                            </tr>
                            <tr>
                                <td>Наложение пломбы из СИЦ «Витример»</td>
                                <td>1500  &#8381;</td>
                            </tr>
                            <tr>
                                <td>Наложение пломбы из светокомпозита</td>
                                <td>1500-2000  &#8381;</td>
                            </tr>
                            <tr>
                                <td>Фиксация анкерного штифта на цемент химического отверждения</td>
                                <td>500  &#8381;</td>
                            </tr>
                            <tr>
                                <td>Фиксация анкерного штифта на цемент светового отверждения «Витример»</td>
                                <td>1000  &#8381;</td>
                            </tr>
                            <tr>
                                <td>Снятие зубных отложений с 1 группы (6-8зубов)</td>
                                <td>1500  &#8381;</td>
                            </tr>
                            </tbody>
                        </Table>



                    </div>
                </div>
            </div>
        </div>
    );
}

export default Price;