import * as React from "react"
import Header from "../components/header/header";
import Footer from "components/footer/footer.jsx";
import BackToTop from "components/back-to-top/back-to-top.jsx";
import Reception from "components/reception/reception.jsx";
import Doctors from "components/doctors/doctors.jsx";
import Seo from "components/seo/seo.jsx";
import Price from "components/price/price.jsx";


const PricePage = () => {



    return (
        <React.Fragment>
            <Seo />
            <Header />
            <Price />
            <Footer />
            <BackToTop />
        </React.Fragment>

    )
}

export default PricePage
